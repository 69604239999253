// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://rewardshub-stage-api.poshvine.com/api/v1/',
  clientId: '9835e1a6-bf97-42f6-a9e2-39790261f9d3',
  projectId: '89f8dc8a-af88-49b2-8b28-c343c2e626f1',
  giftCard: {
    apiUrl: 'https://sandbox-offerservice.poshvine.com/offer_service/internal/',
    clientToken: '1234',
  },
  psApiUrl: 'https://sandbox-api.poshvine.com/ps/v1/',
  csApiUrl: 'https://sandbox-api.poshvine.com/cs/v1/',
  bsApiUrl: "https://sandbox-api.poshvine.com/bs/v1/",
  paApiUrl: "https://sandbox-api.poshvine.com/pa/v1/",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
