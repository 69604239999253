import { Component, OnInit } from "@angular/core";
import { Auth0Service } from './shared/services/auth0/auth0.service';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "intermiles";

  constructor(
    public auth: Auth0Service,
    private update: SwUpdate,
  ) {
  }

  ngOnInit() {
    this.auth.checkSession();
    this.updateClient();
  }


  checkGdpr(){
    if(document.getElementsByClassName('notificationBannerwrapper') && document.getElementsByClassName('notificationBannerwrapper').length > 1 ){
      (document.getElementsByClassName('notificationBannerwrapper')[1] as HTMLElement).style.display = 'none';
    }
    return true;
  }

  checkHeader(){

    if(document.getElementsByClassName('jp-header-fixed') && document.getElementsByClassName('jp-header-fixed').length > 0){
      this.auth.headerPresent = true;
    }else{
      this.auth.headerPresent = false;
    }
    console.log(this.auth.headerPresent)
    return true;
  }
  
  updateClient() {
    if (!this.update.isEnabled) {
      console.log('Not Enabled');
      return;
    }
    this.update.available.subscribe((event) => {
      console.log(`current`, event.current, `available `, event.available);
      if (confirm('Update available ! Please click on Ok to update.')) {
        this.update.activateUpdate().then(() => location.reload());
      }
    });

    this.update.activated.subscribe((event) => {
      console.log(`current`, event.previous, `available `, event.current);
    });
  }

}
