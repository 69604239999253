import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  csApiUrl = environment.csApiUrl;

  constructor(private apiService: ApiService) { }

  createTokenSession(token) {
    return this.apiService.post(this.csApiUrl + 'sessions', { token: token });
  }

  logout() {
    localStorage.clear();
  }

  deleteToken() {
    if (JSON.parse(localStorage.getItem('currentUser'))) {
      const currentUserId = JSON.parse(localStorage.getItem('currentUser')).id;
      return this.apiService.post(this.csApiUrl + 'sessions/' + currentUserId, {}).toPromise();
    }
  }

  getUserDetails() {
    return this.apiService.get(this.csApiUrl + 'user', {});
  }

  isAuthenticated() {
    return localStorage.getItem('token') ? true : false;
  }
  
}
