import { Injectable } from '@angular/core';
import auth0 from 'auth0-js';
import { AUTH_CONFIG } from '../../interfaces/auth0-variables';
import { AuthService } from '../auth/auth.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class Auth0Service {

  public headerPresent : Boolean = true;

  auth0 = new auth0.WebAuth({
    clientID: AUTH_CONFIG.clientID,
    domain: AUTH_CONFIG.domain,
    responseType: AUTH_CONFIG.responseType,
    redirectUri: AUTH_CONFIG.callbackURL,
    callbackURL: AUTH_CONFIG.callbackURL,
    scope: AUTH_CONFIG.scope
  });
  sessionData;
  progress: boolean = true; //make this true // uncomment

  constructor(private authService: AuthService, private router: Router) {
  }

  checkSession() {

    this.auth0.checkSession({
      redirectUri: AUTH_CONFIG.callbackURL,
    }, (err, result) => {
      console.log(err, result)
      this.loadScript1();
      this.loadScript2();
      if (result) {
        this.sessionData = result.idTokenPayload;
        this.authService.createTokenSession(result.idToken).subscribe((res: any) => {
          if (res && res.success) {
            localStorage.setItem('token', res.token);
            this.authService.getUserDetails().subscribe((res: any) => {
              localStorage.setItem('currentUser', JSON.stringify(res));
              this.progress = false;
            });
          } else {
            this.progress = false;
          }
        });
        window['UD'] = {
          JPNumber: result.idTokenPayload.nickname.substr(result.idTokenPayload.nickname.length - 9),
          name: result.idTokenPayload.name,
          PointsBalanace: result.idTokenPayload['https://www.intermiles.com/cris_Member_PointsBalance'],
          tier: result.idTokenPayload['https://www.intermiles.com/cris_Member_Tier'],
          EmailVerified: result.idTokenPayload['https://www.intermiles.com/cris_Member_IsEmailVerified'],
          MobileVerified: result.idTokenPayload['https://www.intermiles.com/cris_Member_IsMobileVerified'],
          PersonId: result.idTokenPayload['https://www.intermiles.com/cris_Member_PersonID'],
          activeStatus: result.idTokenPayload['https://www.intermiles.com/cris_Member_ActiveStatus'],
          age: "",
          cityofResidence: "",
          countryofResidence: result.idTokenPayload['https://www.intermiles.com/cris_Member_CountryOfResidence'],
          gender: result.idTokenPayload['https://www.intermiles.com/cris_Member_Gender'],
          firstloginDate: result.idTokenPayload['https://www.intermiles.com/created_at'],
          lastloginDate: result.idTokenPayload['https://www.intermiles.com/cris_Member_LastLoginDate'],
          memProgram: result.idTokenPayload['https://www.intermiles.com/cris_Member_MemProgram'],
          LoggedInUsing: "JPNO"
        };
      } else {
        this.authService.deleteToken();
        this.authService.logout();
        delete window['UD'];
        // this.router.navigateByUrl('/');
        this.progress = false;
      }
    });
  }

  loadScript1() {
    let body = document.head;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'https://uatresources.jetprivilege.com/sharedresources/js/HeaderFooter.js';
    script.type = "text/javascript";
    body.appendChild(script);
  }

  loadScript2() {
    let body = document.head;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'https://uatresources.jetprivilege.com/sharedresources/staticJS/JPPLCommonGlobal.js';
    script.type = "text/javascript";
    body.appendChild(script);
  }

}
