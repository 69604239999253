import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse
} from "@angular/common/http";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { AuthService } from '../auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: "root"
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    request = request.clone({
      setHeaders: {
        'X-Client-Id': environment.clientId,
        'X-Project-Id': environment.projectId,
      }
    });
    if (localStorage.getItem('token')) {
      request = request.clone({
        setHeaders: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      });
    }
    return next.handle(request).pipe(
      tap(
        (response: HttpEvent<any>) => {
          console.log(response);
        },
        (error: HttpErrorResponse) => {
          console.log(error);
          if (error["status"] != 200 && error["status"] != 401 && error["status"] != 404) {
            // this.router.navigate(["error"]);
          } else if (error["status"] == 401) {
            this.authService.deleteToken();
            this.authService.logout();
            delete window['UD'];
            // this.router.navigateByUrl('/');
          }
        }
      )
    );
  }
}
